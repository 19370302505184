import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import localeMx from '@angular/common/locales/es-MX';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { HttpClientModule } from '@angular/common/http';
import { registerLocaleData } from '@angular/common'; // hash , HashLocationStrategy, LocationStrategy 
import { FlexLayoutModule } from '@angular/flex-layout';
import { PrintLayoutComponent } from './panel/print-layout/print-layout.component';
import { BoletoComponent } from './panel/caja/reservaciones/boleto/boleto.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { QRCodeModule } from 'angularx-qrcode';
import { PrintService } from './print.service';

registerLocaleData(localeMx, 'es-MX');
@NgModule({
  declarations: [
    AppComponent,
    PrintLayoutComponent,
    BoletoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatFormFieldModule,
    HttpClientModule,
    FlexLayoutModule,
    MatGridListModule,
    MatIconModule,
    QRCodeModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-MX' }, 
    PrintService,
    // {provide: LocationStrategy, useClass: HashLocationStrategy}// Hash
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
