export class User {

  private _idUsuario: number;
  private _nombre: string;
  private _email: string;
  private _token: string;

  constructor(idUsuario: number= -1, nombre: string = '', email: string, token: string = '') {
      this._idUsuario = idUsuario;
      this._nombre = nombre;
      this._email = email;
      this._token = token;
  }

  public get idUsuario(): number {
      return this._idUsuario;
  }

  public set idUsuario(idUsuario: number) {
      this._idUsuario = idUsuario;
  }

  public get nombre(): string {
      return this._nombre;
  }

  public set nombre(nombre: string) {
      this._nombre = nombre;
  }

  public get email(): string {
      return this._email;
  }

  public set email(email: string) {
      this._email = email;
  }

  public get token(): string {
      return this._token;
  }

  public set token(token: string) {
      this._token = token;
  }

}
