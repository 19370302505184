import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { tap, share, catchError } from 'rxjs/operators';
import { Observable, BehaviorSubject, of, throwError } from 'rxjs';

import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';
import { AuthResponse } from './auth-response';
import { LoginRequest } from './login/login-request.model';
import { User } from '../models/user.model';
// import { SignUpRequest } from './register/signup-request.model';
import { DEFAULTS } from '../app.conf'; 

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  AUTH_SERVER_ADDRESS = DEFAULTS.URL_BASE;

  public loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public user: User;
  public userSource: BehaviorSubject<User> = new BehaviorSubject<User>(undefined);

  constructor(private httpClient: HttpClient,
              @Inject(SESSION_STORAGE) private storage: StorageService) { }

  public init(): void {

    this.getToken().then(async (token) => {

      if (token && !this.loggedIn.getValue()) {
        this.loadUser(token);
        this.loggedIn.next(true);
      }
    });

  }

  public login(request: LoginRequest): Observable<AuthResponse> {

    let body: any;
    let address: string;
    body = { email: request.email, password: request.password};
    address = this.AUTH_SERVER_ADDRESS + '/login';

    return this.httpClient.post<AuthResponse>(address, body).pipe(

      tap(async (response: AuthResponse) => {

        const token = response.token.split('.')[0];
        await this.storage.set('ACCESS_TOKEN', token);
        this.loadUser(token);
        this.loggedIn.next(true);
      }),
      catchError(this.handleError)
    );
  }

  public async logout(): Promise<void> {
      try {
        await this.storage.remove('ACCESS_TOKEN');
        delete this.user;
        this.loggedIn.next(false);
      } catch (err) {

      }
  }


  private handleError(error: HttpErrorResponse): Observable<never> {

    if (error.error instanceof ErrorEvent) {

      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error.error.error);
  }

  public async getToken(): Promise<string> {

    try {

      const token = await this.storage.get('ACCESS_TOKEN');
      if (token) {

        return token;
      } else {

        return undefined;
      }
    } catch (error) {

      return undefined;
    }

  }

  public loadUser(token: string): void {

    if (this.user === undefined) {

      const decodedUser = JSON.parse(atob(token));
      this.user = new User(decodedUser.idUsuario, decodedUser.nombre, decodedUser.email, token);
      this.userSource.next(this.user);
    }
  }

  public isLoggedIn(): Observable<boolean> {

    return this.loggedIn.asObservable().pipe(share());
  }

  public getUser(): Observable<User> {
    return this.userSource.asObservable().pipe(share());
  }

}
