import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  isPrinting = false;
  constructor(private router: Router) { }

  printDocument(idBoleto: number) {
    this.isPrinting = true;
    this.router.navigate(['/',
      { outlets: {
        print: ['print', 'boleto', idBoleto]
      }}]);
  }

  onDataReady() {
    setTimeout(() => {
      setTimeout(() => {
        this.isPrinting = true;
        window.print();
      }, 1000);
      setTimeout(() => {
        this.isPrinting = false;
        this.router.navigate([{ outlets: { print: null }}]);
      }, 1000);
    });
  }

}
