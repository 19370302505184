import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(private router: Router,
              private authService: AuthService) {}

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): boolean | Promise<boolean> {

    this.authService.getToken().then((token) => {

      if (token) {
        if (this.authService.user === undefined) {
          this.authService.loadUser(token);
        }
        return true;
      } else {

        this.router.navigateByUrl('login');
        return false;
      }
    });
    return true;
  }
}
