import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './auth/auth-guard.service';
import { PrintLayoutComponent } from './panel/print-layout/print-layout.component';
import { BoletoComponent } from './panel/caja/reservaciones/boleto/boleto.component';
const routes: Routes = [
  { path: '', redirectTo: 'panel', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: './auth/login/login.module#LoginModule'
  },
  {
    path: 'panel',
    loadChildren: './panel/panel/panel.module#PanelModule',
    canActivate: [AuthGuardService]
  },
  { path: 'print',
        outlet: 'print',
        component: PrintLayoutComponent,
        children: [
          { path: 'boleto/:id', component: BoletoComponent }
        ]
      }
];

@NgModule({
  imports: [
    // RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }) //Angular material
    RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
