import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrintService } from 'src/app/print.service';
import { ReservacionService } from '../reservacion.service';
import { Reservacion } from 'src/app/models/reservacion.model';

@Component({
  selector: 'app-boleto',
  templateUrl: './boleto.component.html',
  styleUrls: ['./boleto.component.scss']
})
export class BoletoComponent implements OnInit {

  public boleto: Reservacion;

  constructor(private route: ActivatedRoute,
              private reservacionService: ReservacionService,
              private printService: PrintService) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {

      const idReservacion = params.get('id');
      this.reservacionService.getReservacion(idReservacion).subscribe( boleto => {
        this.boleto = boleto;
        this.printService.onDataReady();
      });
    });
  }

}
