import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';

import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { share, catchError, tap } from 'rxjs/operators';
import { User } from 'src/app/models/user.model';
import { AuthService } from 'src/app/auth/auth.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Viaje } from 'src/app/models/viaje.model';
import { Pasajero } from 'src/app/models/pasajero.model';
import { Horario } from 'src/app/models/horario.model';
import { Cupon } from 'src/app/models/cupon.model';
import { Reservacion } from 'src/app/models/reservacion.model';
import { Asiento } from 'src/app/models/asiento.model';
import { Pago } from 'src/app/models/pago.model';
import { DEFAULTS } from '../../../app.conf'; 


@Injectable({
  providedIn: 'root'
})
export class ReservacionService {

  public usuario: User;

  public viaje: Viaje;
  public asientosSeleccionados: number[];
  public email: string;


  AUTH_SERVER_ADDRESS = DEFAULTS.URL_BASE;

  constructor(
    private authService: AuthService,
    private httpClient: HttpClient
  ) {

    this.authService.getUser().subscribe( user => {
      this.usuario = user;
    });
    this.asientosSeleccionados = [];
    this.email = '';
  }

  public setViaje( viaje: Viaje) {

    this.viaje = viaje;
  }

  public getReservacion(idReservacion: string) {

    const address = `${ this.AUTH_SERVER_ADDRESS }/reservaciones/${ idReservacion }`;
    return this.httpClient.get<Reservacion>(address).pipe(
      catchError(this.handleError)
    );
  }

  public getHorarios(): Observable<Horario[]> {

    let origen: string;
    let destino: string;
    let fecha: string;

    fecha = formatDate(new Date(this.viaje.fecha), 'yyyy-MM-dd-HH-mm', 'es-MX');
    origen = this.viaje.origen.clave;
    destino = this.viaje.destino.clave;

    const address = `${ this.AUTH_SERVER_ADDRESS }/viajes/horarios/${ fecha }/${ origen }/${ destino }`;

    return this.httpClient.get<Horario[]>(address).pipe(
      catchError(this.handleError)
    );
  }

  public registrarFecha(horario: Horario) {

    this.getViaje(horario.idViaje).subscribe( viaje => {

      this.viaje = viaje;

    });
  }

  public getViaje(idViaje: string): Observable<Viaje> {
    const address = `${ this.AUTH_SERVER_ADDRESS }/viajes/${ idViaje }`;

    return this.httpClient.get<Viaje>(address).pipe(
      catchError(this.handleError)
    );
  }

  public seleccionaLugar(idAsiento: number, accion: string): Observable<any> {

    let address: string;
    const body: any = {};
    const idViaje = this.viaje.idViaje;

    switch (accion) {

      case 'agregar':
        address = `${ this.AUTH_SERVER_ADDRESS }/asientos/estado/${ idViaje }/${ idAsiento + 1 }`;

        body.idUsuario = this.usuario.idUsuario;
        body.estado = 1;
        break;

      case 'quitar':
        address = `${ this.AUTH_SERVER_ADDRESS }/asientos/${ idViaje }/${ idAsiento + 1 }`;

    }

    return this.httpClient.put(address, body).pipe(
      tap( response => {

        if ( accion === 'agregar') {
          this.viaje.asientos[idAsiento].idUsuario = this.usuario.idUsuario;
          this.viaje.asientos[idAsiento].estado = 1;
          this.asientosSeleccionados.push(idAsiento + 1);

        } else if ( accion === 'quitar') {

          this.viaje.asientos[idAsiento].idUsuario = 0;
          this.viaje.asientos[idAsiento].estado = 0;
          this.asientosSeleccionados.splice( this.asientosSeleccionados.indexOf(idAsiento + 1) , 1);

        }
      }),
      catchError(this.handleError)
    );
  }

  public puedeSeleccionar(numPasajeros: number): boolean {

    return this.asientosSeleccionados.length < numPasajeros;
  }

  public modificarReservacion(idReservacion: number, _tipoViaje: string, _asientosOriginales: number[]): Observable<any> {

    const address = `${ this.AUTH_SERVER_ADDRESS }/reservaciones/${ idReservacion }`;
    const body = {
      tipoViaje : _tipoViaje,
      idUsuario: this.usuario.idUsuario,
      idViaje: this.viaje.idViaje,
      asientos: this.asientosSeleccionados,
      email: this.email
    };

    this.asientosSeleccionados = [];
    this.email = '';
    return this.httpClient.put(address, body).pipe(
      catchError(this.handleError)
    );
  }

  public modificarPasajeros(_pasajeros: Pasajero[], idReservacion: number) {

    const address = `${ this.AUTH_SERVER_ADDRESS }/reservaciones/${ idReservacion }/pasajeros`;

    const body = { pasajeros: _pasajeros };

    return this.httpClient.put(address, body).pipe(
      catchError(this.handleError)
    );
  }

  public pagar(
                reservacion: Reservacion,
                pago: Pago,
                usuario: User
              ): Observable<any> {

    const address = `${ this.AUTH_SERVER_ADDRESS }/reservaciones/pago/caja/${ reservacion.idReservacion }`;
    const _idViajeVuelta = reservacion.viajeVuelta ? reservacion.viajeVuelta.idViaje : null;
    const _asientosVuelta = reservacion.viajeVuelta ? reservacion.viajeVuelta.asientos : [];
    const body = {
      tipoBoleto: reservacion.tipoBoleto,
      idViajeIda: reservacion.viajeIda.idViaje,
      asientosIda: reservacion.viajeIda.asientos,
      idViajeVuelta: _idViajeVuelta,
      asientosVuelta: _asientosVuelta,
      email: usuario.email,
      efectivo: pago.efectivo,
      tarjeta: pago.tarjeta,
      otro: pago.otro,
      caja: pago.caja
    };

    return this.httpClient.put(address, body).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse): Observable<never> {

    if (error.error instanceof ErrorEvent) {

      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error.error}`);
    }
    // return an observable with a Ruta-facing error message
    return throwError(error.error.error);
  }

}
